import './App.css';
import React from "react";
import { useState, useEffect } from 'react';
import { Routes, Route, useLocation} from "react-router-dom";


//Components
import NavBar from "./components/navBar/navBar";
import Footer from "./components/Footer/footer";

//Pages
import Home from "./pages/home/home";
import AboutUs from "./pages/aboutus/aboutus";
import Distributorship from "./pages/distributorship/distributorship";
import Contact from "./pages/contact/contact";

const App = ()=> {
  return (
    <div className="App">
      <NavBar/>
       <Routes>
         <Route path="/" element= { <Home/> }/>
         <Route path="about" element = { <AboutUs/> }/>
         <Route path="distributorship" element = { <Distributorship/> } />
         <Route path="contact" element = { <Contact/> }/>
       </Routes>
       <Footer/>
    </div>
  );
}

export default App;
