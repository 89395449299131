import React from 'react'
import './contact.css';
import { useState, useEffect } from 'react';
import {Helmet} from "react-helmet";

const Contact = ()=>{
	const [smallStyle, setSmallStyle] = useState(false);

	useEffect(()=>{

		window.addEventListener('resize', (event)=>{
			let screenWidth = document.documentElement.clientWidth;

			if (screenWidth <= 920)
			{
				setSmallStyle(true)
			}
			else
			{
				setSmallStyle(false);
			}
		});

	}, [smallStyle])

	
	//On initial window load
	useEffect(()=>{
		
		let screenWidth = document.documentElement.clientWidth;

		if (screenWidth <= 920)
		{
			setSmallStyle(true)
		}
		else
		{
			setSmallStyle(false);
		}

	}, [smallStyle])

	return (
		<div className={!smallStyle ? "ContactTop" : "ContactTopSmall"}>
			<Helmet>
				<title>Pasarnow - Contact</title>
				<meta name="description" content="Food supply chain platform helping various B2B customers source fresh & staple products directly from farmers and principals." />
				<meta name="keywords" content="PDD, Pasarnow, Sayuran, Buah, Fruits, Vegetables, Fresh Produce, Online Market, Distributor" />
			</Helmet>
			<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3966.4074452256978!2d106.7711968!3d-6.209869599999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f700218507ef%3A0xd49fd0482ef9406c!2sPT%20Anex%20Mitra%20Internusa!5e0!3m2!1sen!2shk!4v1723623462034!5m2!1sen!2shk" className="GoogleMaps" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
			<div className="ContactBody">
				<div className={!smallStyle ? "ContactColumn" : "ContactColumnSmall"}>
					<h2>ADDRESS</h2>
					<label>Jl. Panjang Arteri Klp. Dua Raya, No. 61 Blok D-E, RT.1/RW.2, Klp. Dua, Kec. Kb. Jeruk, Kota Jakarta Barat, DKI Jakarta, 11550, Indonesia</label>
				</div>

				<div className={!smallStyle ? "ContactColumn" : "ContactColumnSmall"}>
					<h2>GENERAL E-MAIL</h2>
					<label><a href="mailto:info@pasarnow.com">info@pasarnow.com</a></label>
					<h2>DISTRIBUTION PARTNERSHIP</h2>
					<label><a href="mailto:pdd@pasarnow.com">pdd@pasarnow.com</a></label>
				</div>

				<div className={!smallStyle ? "ContactColumn" : "ContactColumnSmall"}>
					<h2>WHATSAPP</h2>
					<label><a href="https://wa.me/+6281388206151" target="_blank">+62 813-8820-6151</a></label>
				</div>
			</div>
		</div>
	)
}

export default Contact;